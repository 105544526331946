export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/pages/about": [4],
		"/pages/affiliate": [5],
		"/pages/app": [6],
		"/pages/b2b": [7],
		"/pages/collaboration": [8],
		"/pages/contact": [9],
		"/pages/faq": [10],
		"/pages/press": [11],
		"/pages/usermanual": [12],
		"/policies/cookies": [13],
		"/policies/privacy-policy": [14],
		"/policies/refund-policy": [15],
		"/policies/shipping-policy": [16],
		"/policies/terms-of-service": [17],
		"/products/[handle]": [18],
		"/store": [19,[],[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';